import "core-js/modules/es.array.push.js";
//https://blog.csdn.net/qq_41954585/article/details/122585512
import { ref, nextTick, computed, watch } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { ElLoading } from "element-plus";
import * as echarts from "echarts";
import { getSummary, getRemoteList } from "@/service/index.js";
import { alertMessage } from "@/assets/js/tool.js";
import { getLocalStorage } from "@/assets/js/storage.js";
import { ajax } from "jquery";
export default {
  setup() {
    //data
    const {
      t
    } = useI18n();
    const store = useStore();
    /**下拉框当前选中*/
    const value = ref("all");
    /**是否显示echarts*/
    const echartsShow = ref(false);
    const loading = ElLoading.service({
      lock: true,
      background: "rgba(0, 0, 0, 0)"
    });
    /**设备总数*/
    const summary = ref({});
    /*设备统计饼状图数据*/
    const summaryArr = ref([]);
    /**远程设备列表*/
    const remoteList = ref([]);
    const oldList = ref([]);
    //多语言
    const lang = computed(() => store.getters.getLang);
    //禁止双击多次触发
    const timer = ref(null);
    watch(lang, () => {
      if (!summaryArr.length) return;
      summaryArr.value[0].name = t("message.other");
      initEcharts(summaryArr.value);
    });
    initPageData();

    //function
    /**初始化页面数据*/
    async function initPageData() {
      await nextTick();
      await initSummary();
      await initRemoteList();
      loading.close();
    }
    /**初始化设备统计*/
    async function initSummary() {
      return getSummary().then(res => {
        if (res.status !== 0) {
          if (res.status !== -102) {
            let text = res ? res.msg || t("failed.getData") : t("failed.getData");
            alertMessage("error", text);
          }
          return;
        }
        let data = res.data || {};
        let percent = data.percent || {};
        let arr = [];
        for (let k in percent) {
          arr.push({
            name: k === "other" ? t("message.other") : k,
            value: percent[k]
          });
        }
        let summaryVal = {
          online: data.online || 0,
          offline: data.offline || 0
        };
        summaryVal.total = summaryVal.online + summaryVal.offline;
        summaryArr.value = arr;
        //初始化echarts
        initEcharts(arr);
        summary.value = summaryVal;
      });
    }

    /**初始化设备列表*/
    async function initRemoteList() {
      return getRemoteList().then(res => {
        if (res.status === 0) {
          remoteList.value = res.data;
          oldList.value = res.data;
        } else {
          if (res.status === -102) return;
          let text = res ? res.msg || t("failed.getData") : t("failed.getData");
          alertMessage("error", text);
        }
      });
    }

    /**初始化echarts*/
    async function initEcharts(arr = []) {
      //v-if 强制刷新
      echartsShow.value = false;
      echartsShow.value = true;
      await nextTick();
      let dom = document.getElementById("equipmentChart");
      if (!dom) return;
      let myChart = echarts.init(dom);
      // 绘制图表
      myChart.setOption({
        title: {
          //标题
          text: "",
          //t('message.model'),
          //x坐标，单位px
          x: "14",
          //y坐标，单位px
          y: "13",
          textStyle: {
            fontSize: 16,
            fontWeight: "400",
            color: "rgba(0,0,0,0.9)"
          }
        },
        //鼠标移入提示框
        tooltip: {
          trigger: "item",
          formatter: `{b} : {c} <br/>${t("message.percentage")} : {d}%`
        },
        //图列设置
        legend: {
          //orient 设置布局方式，默认水平布局，可选值：'horizontal'（水平） ¦ 'vertical'（垂直）
          orient: "vertical",
          //x 设置水平安放位置，默认全图居中，可选值：'center' ¦ 'left' ¦ 'right' ¦ {number}（x坐标，单位px）
          x: "176",
          //y 设置垂直安放位置，默认全图顶端，可选值：'top' ¦ 'bottom' ¦ 'center' ¦ {number}（y坐标，单位px）
          y: "center",
          itemWidth: 30,
          // 设置图例图形的宽
          itemHeight: 16,
          // 设置图例图形的高
          textStyle: {
            color: "rgba(0,0,0,0.9)" // 图例文字颜色
          },

          //itemGap设置各个item之间的间隔，单位px,横向布局时为水平间隔，纵向布局时为纵向间隔
          itemGap: 7
        },
        series: [{
          type: "pie",
          //饼图
          radius: ["40%", "65%"],
          //饼图的大小
          center: ["30%", "51%"],
          //饼图的位置
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: "center"
          },
          emphasis: {
            label: {
              show: false,
              fontSize: "20"
            }
          },
          labelLine: {
            show: false
          },
          //数据
          data: arr
        }]
      });
    }

    /**跳转到第三方连接*/
    async function goToLink(item) {
      //先验证登录是否失效
      let result = await getSummary();
      if (result && result.status === 0) {
        let sid = getLocalStorage("sid") || "";
        let lang = getLocalStorage("lang") || "en";
        //`http://${item.outerIp}:8081/home?natCross_token=${item.deviceId}&natCross_session=${sid}&lang=${lang}`;
        let langText = lang === "en" ? "en_US" : "zh_CN";
        localStorage.setItem("locale", langText);
        let url = `http://${item.outerIp}:8081/proxy/${item.deviceId}/${sid}/home`;
        //deviceType为空 则是老版本 特殊处理
        if (!item.deviceType) {
          url = url + `?natCross_token=${item.deviceId}&natCross_session=${sid}&lang=${lang}`;
        }
        //verifyURL(url); //验证网站是否有效 这个版本先注释 服务未部署https 下个版本再放出来
        window.open(url);
      } else {
        let errorMsg = result ? result.msg || t("message.OperationFailed") : t("message.OperationFailed");
        alertMessage("error", errorMsg);
      }
    }

    /**下拉框值发送变化*/
    function hanldeSelectChange(val) {
      let newList = [];
      if (val === "all") {
        remoteList.value = oldList.value;
      } else if (val === "online") {
        oldList.value.forEach(item => {
          if (item.status === 0) {
            newList.push(item);
          }
        });
        remoteList.value = newList;
      } else {
        oldList.value.forEach(item => {
          if (item.status === -1) {
            newList.push(item);
          }
        });
        remoteList.value = newList;
      }
    }

    /**验证url是否有效 无效则不跳转*/
    function verifyURL(url) {
      if (timer.value) clearTimeout(timer.value);
      timer.value = setTimeout(() => {
        ajax({
          type: "get",
          cache: false,
          //不缓存
          url: url,
          dataType: "jsonp",
          processData: false,
          timeout: 1000,
          complete: function (data) {
            if (data.status == 200) {
              window.open(url);
            } else {
              alertMessage("error", t("failed.ServiceException"));
            }
          }
        });
      }, 200);
    }
    return {
      //data
      value,
      echartsShow,
      summary,
      remoteList,
      //function
      initPageData,
      initSummary,
      initRemoteList,
      initEcharts,
      goToLink,
      hanldeSelectChange,
      verifyURL
    };
  }
};